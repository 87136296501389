<template>
  <div>
    <a-layout class="custom-layout">
      <a-layout-sider
        style="background: #ffffff; overflow: hidden"
        class="custom-sider"
      >
        <div class="custom-box">
          <div
            v-for="(item, index) in menus"
            :key="index"
            @click="goToUrl(index)"
            class="second-menu-item"
            :class="{ on: getSelectKey.indexOf(item.key) > -1 }"
          >
            <i class="iconfont ft14" :class="item.icon"></i>
            <span class="ft14 ml10">{{ item.name }}</span>
          </div>
        </div>
      </a-layout-sider>
      <a-layout-content style="padding: 0px 20px; background: #f7f8fa">
        <div>
          <router-view></router-view>
        </div>
      </a-layout-content>
    </a-layout>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menus: [
        {
          name: "站点设置",
          icon: "iconshezhi1",
          key: "setting_index",
          link: "/setting",
        },
        {
          name: "客服",
          icon: "iconsubnav_miniprogram03",
          key: "setting_kefu",
          link: "/setting/kefu",
        },
        {
          name: "短信配置",
          icon: "iconmessage",
          key: "setting_sms_setting",
          link: "/setting/smssetting",
        },
        {
          name: "订阅消息",
          icon: "iconmessage",
          key: "setting_message",
          link: "/setting/message",
        },
        {
          name: "存储设置",
          icon: "iconcunchu",
          key: "setting_oss",
          link: "/setting/oss",
        },
        {
          name: "支付设置",
          icon: "iconzhifushezhi",
          key: "setting_payment",
          link: "/setting/payment",
        },
        {
          name: "小程序/公众号",
          icon: "iconkaifangpingtai",
          key: "setting_open",
          link: "/setting/open",
        },
        {
          name: "账号管理",
          icon: "iconshezhi1",
          key: "setting_user_info",
          link: "/setting/userInfo",
        },
      ],
    };
  },
  mounted() {
    let box = document.querySelector(".ant-layout-sider-children");
    let activeEl = box.querySelector(".second-menu-item.on");
    box.scrollLeft = activeEl.offsetLeft;
  },
  methods: {
    goToUrl(index) {
      this.refreshPage(this.menus[index].link);
    },
    addAct() {
      this.$router.push("/application/edit");
    },
  },
};
</script>

<style>
.merchant-layout-audit-num {
  background: #ff6600;
  border-radius: 8px;
  padding: 0px 5px 0px 5px;

  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
}
</style>
